@import "../../assets/styles/mainVariables";
@import "../../assets/styles/mixins";
@import "../../assets/styles/fonts";

.header-wrapper {
  background-color:rgba(70, 136, 246, 0.80);
  position: relative;
  width: 100%;
  top: 0;
  z-index: 1000;

  .header-box-wrapper {
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 $mainPadding;

    .header-logo {
      display: flex;
      align-items: center;
      margin-right: 0;

      span{
        font-size: 34px;
        font-weight: 500;
        line-height: 21px;
        letter-spacing: 0;
        text-align: left;
        color: white;
        margin-left: 34px;
      }
    }

    .nav-bar {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: calc(100% - 100px);
      height: 100%;

      .nav-item {
        height: 100%;
        font-size: 18px;
        color: white;
        margin-left: 40px;
        cursor: pointer;
        letter-spacing: 0.5px;
        line-height: 16px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: flex;
        align-items: center;
        position: relative;
        p{
          font-weight: 700;
          text-shadow: 0 4px 4px  #00000040;
        }
        .languages-wrapper{
          width: 100px;
          height: 42px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: row;
          background-color:$brandSecColor;
          box-shadow: 0 4px 4px 0 #00000040;
          .dropdown-menu-item{
            display: flex;
            align-items: center;
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: 0;
            text-align: center;
            color:$brandColor;
            img{
              margin-right: 8px;
            }
            svg{
              margin-left: 4px;
            }
          }
        }
      }

      .service-center {
        @include flex-center;
        white-space: nowrap;
        color: white;
        height: 45px;
        width: 200px;
        font-size: 14px;
        margin-left: 60px;
        font-family: Medium;
        letter-spacing: 0.4px;
        background-color: $brandColor;
        border-radius: 4px;

        span {
          margin-top: 2px;
        }

        .service-center-icon {
          margin-right: 14px;

          path {
            fill: white;
          }
        }
      }
    }

    @media all and (max-width: 1050px) {
      display: none;
    }
  }

  .mobile-nav-bar {
    display: none;
  }

  @media all and (max-width: 1050px) {
    padding: 0;
    .mobile-nav-bar {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 46px;
      background-color: $brandColor;
      padding: 0 $mainPadding;

      .nav-bar-menu {
        $color: white;

        $height-icon: 16px;
        $width-line: 20px;
        $height-line: 2px;

        $transition-time: 0.2s;
        $rotation: 45deg;
        $translateY: ($height-icon / 2);
        $translateX: 0;

        width: $width-line;
        height: $height-icon;
        position: relative;
        display: block;
        cursor: pointer;

        .line {
          display: block;
          background: $color;
          width: $width-line;
          height: $height-line;
          position: absolute;
          left: 0;
          border-radius: 4px;
          transition: all $transition-time;
          -webkit-transition: all $transition-time;
          -moz-transition: all $transition-time;

          &.line-1 {
            top: 0;
          }

          &.line-2 {
            top: 50%;
            transform: translateY(-50%);
          }

          &.line-3 {
            top: 100%;
            transform: translateY(-100%);
          }
        }

        &:hover, &:focus {
          .line-1 {
            transform: translateY(-40%);
          }

          .line-3 {
            transform: translateY(-40%);
          }
        }

        &.active {
          .line-1 {
            transform: translateY($translateY) translateX($translateX) rotate($rotation);
            -webkit-transform: translateY($translateY) translateX($translateX) rotate($rotation);
            -moz-transform: translateY($translateY) translateX($translateX) rotate($rotation);
          }

          .line-2 {
            opacity: 0;
          }

          .line-3 {
            transform: translateY($translateY * -1) translateX($translateX) rotate($rotation * -1);
            -webkit-transform: translateY($translateY * -1) translateX($translateX) rotate($rotation * -1);
            -moz-transform: translateY($translateY * -1) translateX($translateX) rotate($rotation * -1);
          }
        }
      }

      .mobile-logo {
        //width: 100px;
        height: 40px;

        path, polygon {
        }
      }
    }
  }
}


