@import "../../assets/styles/mainVariables";
@import "../../assets/styles/mixins";
@import "../../assets/styles/fonts";

.footer-wrapper{
  padding: 46px $mainPadding;
  background-color: #4688F6;
  .footer-top-block{
    width: 100%;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    margin-top: 26px;
    .first-block{
      width: 30%;
      .first-block-title{
        color:#FFF;
        font-family: Comfortaa;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px;
        letter-spacing: 0.5px;
      }
      .social-block{
        width: 100%;
        margin-top: 20px;
        svg{
          width: 36px;
          margin-right: 20px;
        }
      }
    }
    .second-block{
      width: 34%;
      .subscribe-title{
        color: #FFF;
        font-family: Comfortaa;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px;
        letter-spacing: 0.5px;
      }
      .invalid{
        border: 1px solid red;
      }
      .input-wrapper{
        width: 100%;
        height: 64px;
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 36px;
        margin-top: 20px;
        .custom-input-group{
          width: 60%;
          height: 100%;
          input{
            border: unset;
            width: 100%;
            height: 100%;
            border-radius: 36px;
            &.invalid {
              border: unset!important;
            }
          }
        }
        .send-btn{
          border-radius: 25px;
          border: 2px solid #4688F6;
          background: #4688F6;
          color:#FFF;
          font-family: Comfortaa;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 164px;
          height: 48px;
          margin-right: 8px;
          svg{
            margin-right: 4px;
          }
        }
      }
    }
    .third-block{
      .third-block-title{
        color:  #FFF;
        font-family: Comfortaa;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 26px;
        letter-spacing: 0.5px;
        margin-bottom: 20px;
      }
      .nav-items-block{
        .nav-item{
          color:#FFF;
          font-family: Comfortaa;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: 26px;
          letter-spacing: 0.5px;
          margin-bottom: 8px;
        }
      }
    }
  }
  .footer-bottom-block{
    width: 100%;
    margin-top: 96px;
    display: flex;
    align-items: center;
    justify-content: center;
    .company-block{
      color:#FFF;
      text-align: center;
      font-family: Comfortaa;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0.5px;
    }
    .privacy-policy{
      color:#FFF;
      text-align: center;
      font-family: Comfortaa;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0.5px;
      margin-left: 20px;
    }
  }

  @media screen and (max-width: 1050px){
    .footer-top-block{
      flex-direction: column;
      .first-block{
        width: 100%;
        .first-block-title{
          color:#FFF;
          font-family: Comfortaa;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px;
          letter-spacing: 0.5px;
        }
      }
      .second-block{
        width: 100%;
        margin-top: 22px;
        .subscribe-title{
          color: #FFF;
          font-family: Comfortaa;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 22px;
          letter-spacing: 0.5px;
        }
        .input-wrapper{
          height: 40px;
          .send-btn{
            height: 30px;
            color: #FFF;
            font-family: Comfortaa;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
          }
        }
      }
      .third-block{
        width: 100%;
        margin-top: 30px;
        .third-block-title{
          color: #FFF;
          font-family: Comfortaa;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 22px;
          letter-spacing: 0.5px;
        }
        .nav-items-block{
          display: flex;
          align-items: center;
          justify-content: space-between;
          .nav-item{
            color: #FFF;
            text-align: center;
            font-family: Comfortaa;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 22px;
            letter-spacing: 0.5px;
          }
        }
      }
    }
    .footer-bottom-block{
      margin-top: 42px;
    }
  }
}