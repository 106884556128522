@import "../../../../assets/styles/mainVariables";
@import "../../../../assets/styles/mixins";
@import "../../../../assets/styles/fonts";

.aboutTeam-block{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-direction: row-reverse;
  margin: 80px 0;
  padding-left:$mainPadding;
  position: relative;
  .aboutTeam-background{
    background-color: #E5EFFF;
    position: absolute;
    right: 0;
    width: 60%;
    z-index: -1;
    height: 540px;
  }
  .aboutTeam-left-block{
    width: 80%;
    padding: 50px 0 50px $mainPadding ;
    .aboutTeam-subTitle{
      font-size: 18px;
      font-weight: 700;
      line-height: 42px;
      letter-spacing: 0.5px;
      text-align: left;
      color: #4688F6;
    }
    .aboutTeam-title{
      font-size: 32px;
      font-weight: 700;
      line-height: 38px;
      letter-spacing: 0;
      text-align: left;
      color: #202327;
      margin-top: 10px;
    }
    .aboutTeam-description{
      width: 80%;
      font-size: 20px;
      font-weight: 700;
      line-height: 28px;
      letter-spacing: 0;
      text-align: left;
      color: #737B86;
      margin-top: 46px;
    }
    .aboutTeam-see-more{
      width: 162px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #4688F6;
      font-size: 20px;
      font-weight: 700;
      line-height: 26px;
      letter-spacing: 0.5px;
      text-align: left;
      color: #4688F6;
      margin-top: 26px;
      cursor: pointer;
    }
  }
  .aboutTeam-right-block{
    position: relative;
    left:0;
  }

  @media screen and (max-width: 1050px){
    margin: unset;
    padding-left: unset;
    flex-direction: column;
    .aboutTeam-background{
     display: none;
    }
    .aboutTeam-left-block{
      width: 100%;
      background-color: #E5EFFF;
      padding: 16px 20px 96px 20px;
      .aboutTeam-subTitle{
        color: #4688F6;
        font-family: Comfortaa;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 42px;
        letter-spacing: 0.5px;
      }
      .aboutTeam-title{
        color: #202327;
        font-family: Comfortaa;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 38px;
      }
      .aboutTeam-description{
        width: 100%;
        color: #737B86;
        font-family: Comfortaa;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 22px;
        margin-top: 18px;
      }
      .aboutTeam-icon{
        display: none;
      }
    }
    .aboutTeam-right-block{
      left: unset;
      top: -60px;
      padding: 0 16px;
      svg{
        width: 100%;
        height: 100%;
      }
    }
  }
}