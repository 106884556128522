@import "../../../../assets/styles/mainVariables";
@import "../../../../assets/styles/mixins";
@import "../../../../assets/styles/fonts";


.solution-block{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  margin: 80px 0 ;
  .solution-left-block{
    width: 80%;
    min-height: 100%;
    background-color: #E5EFFF;
    padding: 50px 0 50px $mainPadding ;
    .solution-subTitle{
      font-size: 18px;
      font-weight: 700;
      line-height: 42px;
      letter-spacing: 0.5px;
      text-align: left;
      color: #4688F6;
    }
    .solution-title{
      font-size: 32px;
      font-weight: 700;
      line-height: 38px;
      letter-spacing: 0;
      text-align: left;
      color: #202327;
      margin-top: 10px;
    }
    .solution-description{
      width: 80%;
      font-size: 20px;
      font-weight: 700;
      line-height: 28px;
      letter-spacing: 0;
      text-align: left;
      color: #737B86;
      margin-top: 46px;
    }
    .solution-see-more{
      width: 162px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #4688F6;
      font-size: 20px;
      font-weight: 700;
      line-height: 26px;
      letter-spacing: 0.5px;
      text-align: left;
      color: #4688F6;
      margin-top: 26px;
      cursor: pointer;
    }
  }
  .solution-right-block{
   position: relative;
    left: -120px;
    bottom: 40px;
  }

  @media screen and (max-width: 1050px){
    flex-direction: column;
    .solution-left-block{
      width: 100%;
      padding: 52px 20px;
      .solution-subTitle{
        color: #4688F6;
        font-family: Comfortaa;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 42px;
        letter-spacing: 0.5px;
      }
      .solution-title{
        color: #202327;
        font-family: Comfortaa;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 38px;
      }
      .solution-description{
        color: #737B86;
        font-family: Comfortaa;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 22px;
        margin-top: 24px;
      }
      .solution-see-more{
        width: 100%;
        margin-top: 4px;
      }
    }
    .solution-right-block{
      width: 100%;
      padding: 0 20px;
      left: unset;
      bottom: unset;
      top: -20px;
      svg{
        width: 100%;
        height: 100%;
      }
    }
  }
}