@font-face {
  font-family:Comfortaa;
  src: url(../fonts/Comfortaa-Regular.ttf) format('truetype');
  font-weight: 300 400;
}
@font-face {
  font-family:Comfortaa;
  src: url(../fonts/Comfortaa-Medium.ttf) format('truetype');
  font-weight: 500 600;
}
@font-face {
  font-family: Comfortaa;
  src: url(../fonts/Comfortaa-Bold.ttf) format('truetype');
  font-weight: 700 900;
}

