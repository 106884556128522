@import "../../../assets/styles/mainVariables";
@import "../../../assets/styles/mixins";
@import "../../../assets/styles/fonts";

.main-wrapper {
  position: relative;
  top: 40px;
  width: 100%;
  background-color: #fffcf6;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 $mainPadding;

  .main-bg-wrapper{
    width: 100%;
    height: 1000px;
    position: absolute;
    top: -220px;
    left: 0;
    bottom: 0;
    right: 0;
    img{
      width: 100%;
      height: 100%;
    }
  }

  .main-content {
    width: 42%;
    position: relative;
    z-index: 1000;

    .main-title {
      font-size: 60px;
      font-weight: 700;
      line-height: 68px;
      letter-spacing: 0;
      text-align: left;
      color: white;
      margin-bottom: 62px;
    }

    .main-description {
      font-size: 20px;
      font-weight: 500;
      line-height: 34px;
      letter-spacing: 0;
      text-align: left;
      color: white;
    }

    .download-btn {
      height: 46px;
      width: 210px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: white;
      font-size: 18px;
      font-weight: 700;
      line-height: 34px;
      letter-spacing: 0;
      text-align: left;
      color: $brandColor;
      margin-top: 68px;
    }
  }

  .main-img-wrapper{
    width: 56%;
    .main-img {
      position: relative;
      padding-top: 80.4%;
      img {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
      }

      .box {
        position: absolute;
        top: 0;
        right: 0;
        width: 30%;
        height: 76%;
        z-index: 0;
        background-color: $brandSecColor;
      }
    }
  }

  @media all and (max-width: 1050px) {
    align-items: unset;
    .main-bg-wrapper{
      height: unset;
      top: -40px;
      img{
        height: 340px;
      }
    }
    .main-content {
      width: 70%;
      .main-title{
        margin-bottom: 10px;
        color:  #FFF;
        font-family: Comfortaa;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px;
      }
      .main-description{
        color:  #FFF;
        font-family: Comfortaa;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 21px;
      }
      .download-btn{
        width: 70px;
        height: 27px;
        color:  #4688F6;
        font-family: Comfortaa;
        font-size: 9px;
        font-style: normal;
        font-weight: 700;
        line-height: 34px;
        margin-top: 12px;
      }
    }
  }
}
