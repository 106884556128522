@import "../../../../assets/styles/mainVariables";
@import "../../../../assets/styles/mixins";
@import "../../../../assets/styles/fonts";

.community-block{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin-bottom: 80px;
  .community-left-block{
    width: 56%;
    background-color: #E5EFFF;
    padding: 80px 0 120px $mainPadding;
    .community-subtitle{
      color: #4688F6;
      font-family: Comfortaa;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 42px;
      letter-spacing: 0.5px;
    }
    .community-title{
      width: 60%;
      color:#202327;
      font-family: Comfortaa;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 48px;
      letter-spacing: 0.5px;
    }
    .community-description{
      color:#737B86;
      font-family: Comfortaa;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px;
      letter-spacing: 0.5px;
      width: 68%;
      margin-top: 36px;
    }
  }
  .community-right-block{
    position: absolute;
    width: 54%;
    right: 0;
    padding: 60px 0;
    .slick-slider{
      width: 100%;
      .slick-list{
        width: 100%;
        .slick-track{
          display: flex;
          .slick-slide{
            width: calc(90% / 2)!important;
            padding-right: 20px;
            &:nth-child(even){
              .community-wrapper{
                background-color: #71C8A6;
              }
            }
            .community-wrapper{
                padding: 26px 12px 26px 36px;
                background-color: #4688F6;
                .svg{
                  width: 68px;
                }
                .solve-block-description{
                  color:#FFF;
                  font-family: Comfortaa;
                  font-size: 18px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: 24px;
                  margin-top: 30px;
                }
                .community-user{
                  margin-top: 30px;
                  display: flex;
                  align-items: center;
                  .img-block{
                    width: 70px;
                    height: 70px;
                    img{
                      width: 100%;
                      height: 100%;
                      object-fit: cover;
                    }
                  }
                  .user-info{
                    margin-left: 18px;
                    .user-fullName{
                      color:#FFF;
                      text-align: center;
                      font-family: Comfortaa;
                      font-size: 18px;
                      font-style: normal;
                      font-weight: 700;
                      line-height: 34px;
                      letter-spacing: 0.5px;
                    }
                    .user-address{
                      color: rgba(243, 244, 246, 0.73);
                      font-family: Comfortaa;
                      font-size: 12px;
                      font-style: normal;
                      font-weight: 700;
                      line-height: 26px;
                    }
                  }
                }
              }
          }
        }
      }
    }
    .buttons{
      position: relative;
      margin-top: 24px;
      .prev-btn{
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background-color: #D9D9D975;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 0;
        cursor: pointer;
      }
      .next-btn{
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background-color: #D9D9D975;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 40px;
        cursor: pointer;
      }
    }
  }

  @media screen and (max-width: 1050px){
    flex-direction: column;
    .community-left-block{
      width: 100%;
      padding: 22px 20px 60px 20px;
      .community-subtitle{
        color: #4688F6;
        font-family: Comfortaa;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 42px;
        letter-spacing: 0.5px;
      }
      .community-title{
        width: 100%;
        color: #202327;
        font-family: Comfortaa;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: 0.5px;
        margin-top: 16px;
      }
      .community-description{
        width: 100%;
        color: #737B86;
        font-family: Comfortaa;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 22px;
        letter-spacing: 0.5px;
        margin-top: 34px;
      }
    }
    .community-right-block{
      width: 100%;
      position: relative;
      top: -30px;
      right: unset;
      padding: 0 10px;
      .slick-slider{
        width: 100%;
        .slick-list{
          width: 100%;
          .slick-track{
            display: flex;
            .slick-slide{
              width: 98%!important;
              padding-right: unset;
            }
          }
        }
      }
    }
  }
}