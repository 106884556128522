@import "../../../../assets/styles/mainVariables";
@import "../../../../assets/styles/mixins";
@import "../../../../assets/styles/fonts";

.why-oragic-block{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 100px $mainPadding 120px $mainPadding;
  .why-oragic-header{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .why-oragic-subtitle{
      color: #4688F6;
      text-align: center;
      font-family: Comfortaa;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 42px;
      letter-spacing: 0.5px;
    }
    .why-oragic-title{
      color: #202327;
      font-family: Comfortaa;
      font-size: 38px;
      font-style: normal;
      font-weight: 700;
      line-height: 48px;
      letter-spacing: 0.5px;
      margin-top: 8px;
    }
  }
  .why-oragic-wrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 54px;
    .oragic-item{
      display: flex;
      align-items: center;
      width: 100%;
      margin-bottom: 36px;
      .oragic-description{
        width: calc(94% - 80px);
        height: 114px;
        background-color: #E5EFFF;
        display: flex;
        align-items: center;
        padding:0 40px;
        color: #49505A;
        font-family: Comfortaa;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 26px;
        letter-spacing: 0.5px;
      }
      .oragic-title{
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: calc(206px - 31px);
        height: 114px;
        color:#202327;
        font-family: Comfortaa;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        letter-spacing: 0.5px;
      }
      &:first-child{
        .oragic-title{
          background-color: #D6ECE3;
        }
      }
      &:nth-child(2){
        .oragic-title{
          background-color: #F9EED7;
        }
      }
      &:last-child{
        .oragic-title{
          background-color: #E7DFEA;
        }
      }
    }
  }

  @media screen  and (max-width: 1050px){
    padding: 60px $mainPadding;
    .why-oragic-header{
      .why-oragic-subtitle{
        color: #4688F6;
        text-align: center;
        font-family: Comfortaa;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 42px;
        letter-spacing: 0.5px;
      }
      .why-oragic-title{
        color: #202327;
        font-family: Comfortaa;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 48px;
        letter-spacing: 0.5px;
      }
    }
    .why-oragic-wrapper{
      margin-top: 24px;
      .oragic-item{
        .oragic-title{
          color: #202327;
          font-family: Comfortaa;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 48px;
          letter-spacing: 0.5px;
          width: 112px;
          height: 148px;
        }
        .oragic-description{
          width: calc(100% - 112px);
          color: #49505A;
          font-family: Comfortaa;
          font-size: 12px;
          font-style: normal;
          font-weight: 700;
          line-height: 20px;
          letter-spacing: 0.5px;
          height: 148px;
          padding: 0 16px;
        }
      }
    }
  }
}