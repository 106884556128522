@import "../../../../assets/styles/mainVariables";
@import "../../../../assets/styles/mixins";
@import "../../../../assets/styles/fonts";

.solveSlider-wrapper{
  padding: 80px $mainPadding;
  .solveSlider-header{
    display: flex;
    flex-direction: column;
    align-items: center;
    .solveSlider-title{
      font-size: 38px;
      font-weight: 700;
      line-height: 28px;
      letter-spacing: 0;
      text-align: left;
      color: black;
    }
    .solveSlider-description{
      font-size: 20px;
      font-weight: 600;
      line-height: 28px;
      letter-spacing: 0;
      text-align: center;
      color: black;
      margin-top: 40px;
    }
  }
  .solveSlider{
    margin-top: 46px;
    .slick-slide{
      padding-right: 20px;
      .solve-block{
        width: calc(96% / 3);
        height: 306px;
        background-color: #E5EFFF;
        display: flex!important;
        flex-direction: column!important;
        align-items: center!important;
        justify-content: center!important;
        padding: 20px 26px;
        .solve-block-title{
          font-size: 20px;
          font-weight: 600;
          line-height: 28px;
          letter-spacing: 0.5px;
          text-align: center;
          color: black;
          margin-top: 12px;
        }
        .solve-block-description{
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          letter-spacing: 0.5px;
          text-align: center;
          color: #49505A;
          margin-top: 12px;
        }
      }
    }
    .buttons{
      position: relative;
      margin-top: 36px;
      .prev-btn{
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background-color: #D9D9D975;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 60px;
        cursor: pointer;
      }
      .next-btn{
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background-color: #D9D9D975;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 0;
        cursor: pointer;
      }
    }
  }

  @media screen and (max-width: 1050px){
    .solveSlider-header{
      .solveSlider-title{
        color: #000;
        font-family: Comfortaa;
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: 28px;
      }
      .solveSlider-description{
        color: #49505A;
        text-align: center;
        font-family: Comfortaa;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px;
        margin-top: 20px;
      }
    }
    .solveSlider{
      .slick-slide{
        padding-right: unset;
        .solve-block{
          width: 100%;
        }
      }
    }
  }
}