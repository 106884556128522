@import "../../../../assets/styles/mainVariables";
@import "../../../../assets/styles/mixins";
@import "../../../../assets/styles/fonts";

.features-block{
  padding: 40px $mainPadding;
  .features-header{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .features-title{
      font-size: 38px;
      font-weight: 700;
      line-height: 28px;
      letter-spacing: 0;
      text-align: left;
      color: black;
    }
    .features-description{
      font-size: 20px;
      font-weight: 600;
      line-height: 28px;
      letter-spacing: 0;
      text-align: center;
      color: #49505A;
      margin-top: 42px;
    }
    .features-block-wrapper{
      width: 100%;
      display: flex;
      align-items: stretch;
      justify-content: space-between;
      margin-top: 60px;
      flex-wrap: wrap;
      gap: 24px;
      .blocks{
        width: calc(100% / 4 - 24px);
        min-height: 100%;
        padding: 34px 26px;
        .blocks-title{
          font-size: 24px;
          font-weight: 700;
          line-height: 42px;
          letter-spacing: 0.5px;
          text-align: left;
          color: black;
        }
        .blocks-description{
          font-size: 14px;
          font-weight: 700;
          line-height: 22px;
          letter-spacing: 0.5px;
          text-align: left;
          color: #49505A;
          margin-top: 18px;
        }
        .blocks-see-more{
          width: 114px;
          height: 34px;
          border: 1px solid black;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 20px;
        }
      }
    }
  }

  @media screen and (max-width: 1050px){
    .features-header{
      .features-title{
        color: #000;
        text-align: center;
        font-family: Comfortaa;
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: 30px;
      }
      .features-description{
        color: #49505A;
        text-align: center;
        font-family: Comfortaa;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px;
        margin-top: 18px;
      }
      .features-block-wrapper{
        .blocks{
          //flex-grow: 1;
          width: unset;
        }
      }
    }
  }
}